import { BREAKPOINTS, Image, SearchOption, Search as UISearch } from "@itb/ui";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApp } from "src/hooks/useApp";
import { get_key } from "src/pages/Discover/utils";
import { Strategy } from "src/types/api";
import { DiscoveryItem } from "src/types/discovery";
import { Position } from "src/types/positions";
import { format_strategy_id } from "src/utils/common";
import { get_uuid_from_string } from "src/utils/string";
import styled from "styled-components";

interface SearchProps {
  open: boolean;
  setOpen: (open: boolean) => any;
}

const StyledSearch = styled(UISearch)`
  z-index: 1;
  width: 400px;

  .itb__search__input_container {
    border-bottom: 1px solid ${props => props.theme.colors.border};
  }

  a {
    &:hover {
      color: unset;
    }
  }

  @media(max-width: ${BREAKPOINTS.tablet}) {
    width: 100%;
    top: 64px;
    border-top: 1px solid ${props => props.theme.colors.border};
    margin-top: -1px;
  }
`

const Logos = styled.div`
  display: flex;
`;

const Logo = styled(Image)`
  width: 16px;
  height: 16px;
  margin-right: 5px;

  &:nth-child(2) {
    margin-left: -10px
  }
`;

interface Option extends SearchOption {
  position?: Position;
  item?: DiscoveryItem;
  strategy?: Strategy;
}

const filters: any = {
  "Positions": (option: Option, value: string) => {
    const position = option.position!;
    const words = value.toLowerCase().split(" ").filter(w => !!w);

    return words.every(word => (
      position.tokens_symbols.some(t => t.toLowerCase().includes(word)) ||
      position.name.toLowerCase().includes(word) ||
      position.strategy_id.toLowerCase().includes(word) ||
      position.blockchain_id.includes(word) ||
      position.position_id.includes(word) || 
      (position.use_spark && "spark".includes(word))
    ));
  },
  "Discover": (option: Option, value: string) => {
    const { strategy, item } = option;
    const words = value.toLowerCase().split(" ").filter(w => !!w);
    
    return words.every(word => (
      item?.tokens.toLowerCase().includes(word) ||
      strategy?.name.toLowerCase().includes(word) ||
      item?.blockchain_id.includes(word) ||
      item?.strategy_id.toLowerCase().includes(word)
    ));
  }
}

const Search = (props: SearchProps) => {
  const { positions, strategies, discovery } = useApp();
  const navigate = useNavigate();

  const options = useMemo<Option[]>(() => {
    return [
      ...(positions ?? []).sort((a, b) => a.status.localeCompare(b.status)).map(position => {
        let title = `${format_strategy_id(position.strategy_id)} ${position.name}`;
        if (position.use_spark)
          title = title.replace('Aave', 'Spark');

        return ({ 
          icon: <Logo src={`/assets/blockchains/${position.blockchain_id}.svg`}></Logo>, 
          label: title, 
          category: "Positions", 
          value: position.position_id, 
          props: { to: `/positions/${position.position_id}` },
          position,
        })
      }),
      ...((discovery && strategies) ? discovery : []).map(item => {
        const strategy = strategies.find(s => s.strategy_id === item.strategy_id);
        const title = strategy?.name ?? format_strategy_id(item.strategy_id);
        const id = get_uuid_from_string(get_key(item));

        return ({ 
          icon: (
            <Logos>
              <Logo src={`/assets/protocols/${item.strategy_id}.svg`} fallback={null} />
              <Logo src={`/assets/blockchains/${item.blockchain_id}.svg`} fallback={null} />
            </Logos>
          ),
          label: `${title} (${item.tokens})`, 
          category: "Discover", 
          value: id, 
          props: { to: `/discover/${id}` },
          item,
          strategy,
        })
      }),
    ]
  }, [positions, discovery, strategies]);

  const filter = (option: Option, value: string) => filters[option.category! as string](option, value);

  return (
    <StyledSearch
      options={options}
      resultWrapper={Link}
      filter={filter}
      closeOnSelect
      open={props.open}
      setOpen={props.setOpen}
      onSelect={x => navigate(x.props.to)}
      placeholder="Search..."
    />
  )
}

export default Search