import { Sidebar, SidebarItem } from "@itb/ui";
import { useLocation } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import RiskRadarItem from "./RiskRadarItem";

const Navigation = () => {
  const location = useLocation();
  const { tenant } = useAuth();

  const items: SidebarItem[] = [
    { text: "Portfolio Overview", mobileText: "Overview", icon: "home", path: "/" },
    { text: "Positions", icon: "analytics", path: "/positions", active: location.pathname.startsWith("/positions") },
    { text: "Invoices", icon: "receipt_long", path: "/invoices" },
    { text: "Discover", icon: "lightbulb", path: "/discover", active: location.pathname.startsWith("/discover") },
    { text: "Risk Radar", icon: "risk_radar", wrapper: RiskRadarItem },
  ];

  if (tenant?.staking_config) items.push({ text: "Staking", icon: "ethereum", path: "/staking" });

  return <Sidebar items={items} />;
};

export default Navigation;
