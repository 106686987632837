import React from "react";
import { RouteConfig } from "./Router";

const publicRoutes: RouteConfig[] = [
  { path: "/signin", component: React.lazy(() => import("src/pages/Signin")) },
  { path: "/change-password", component: React.lazy(() => import("src/pages/ChangePassword")) },
  { path: "/forgot-password", component: React.lazy(() => import("src/pages/ForgotPassword")) },
  { path: "/discover", title: "Discover", component: React.lazy(() => import("src/pages/Discover")) },
  { path: "/discover/:key", component: React.lazy(() => import("src/pages/DiscoveryDetails")) },
  { path: "*", component: React.lazy(() => import("src/pages/NotFound")) },
];

export default publicRoutes;
