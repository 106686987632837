import { ScrollableContainer, useUI, BREAKPOINTS } from "@itb/ui";
import Navigation from "src/components/Navigation";
import Router from "src/routes/Router";
import styled, { css } from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Navbar from "src/components/Navbar";
import { useAuth } from "src/hooks/useAuth";
import { useEffect } from "react";
import RUM from "./services/RUM";
import { useLocation } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);

const AppContainer = styled.div`
  display: flex;
  max-height: 100vh;
  overflow: hidden;
`;

const Main = styled.div<{ $sidebarCollapsed: boolean }>`
  width: calc(100% - ${props => props.$sidebarCollapsed ? props.theme.sidebarCollapsedWidth : props.theme.sidebarWidth });

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    margin-bottom: ${props => props.theme.sidebarMobileHeight};
  }
`;

const Content = styled(ScrollableContainer)<{ sidebarCollapsed: boolean }>`
  width: ${props => (props.sidebarCollapsed ? css`calc(100vw - ${props => props.theme.sidebarCollapsedWidth})` : css`calc(100vw - ${props => props.theme.sidebarWidth})`)};
  height: calc(100vh - ${props => props.theme.navbarHeight});
  transition: width 0.5s;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 100vw;
    height: calc(100svh - ${props => props.theme.navbarHeight});
    transition: none;
  }
`;

const App = () => {
  const { sidebarCollapsed } = useUI();
  const { currentTenantId, user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    try {
      RUM?.addSessionAttributes({ 
        tenantId: currentTenantId ?? '',
        user: user?.username ?? ''
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentTenantId, user]);

  useEffect(() => {
    try {
      const page = `${location.pathname}${location.search}${location.hash}`;
      RUM?.recordPageView(page);
    } catch (err) {
      console.log(err);
    }
  }, [location])

  return (
    <AppContainer>
      <Navigation />
      <Main $sidebarCollapsed={sidebarCollapsed}>
        <Navbar />
        <Content sidebarCollapsed={sidebarCollapsed} key={currentTenantId}>
          <Router />
        </Content>
      </Main>
    </AppContainer>
  );
};

export default App;
