interface Env {
  QUANT_API_URL: string | undefined;
  AWS_REGION: string | undefined;
  COGNITO_USER_POOL_ID: string | undefined;
  COGNITO_APP_CLIENT_ID: string | undefined;
  APP_URL: string | undefined;
  CONTACT_EMAIL_ADDRESS: string | undefined;
  WITHDRAWAL_TOOL_URL: string | undefined;
  STAKING_TOOL_URL: string | undefined;
  RUM_GUEST_ROLE_ARN: string | undefined;
  RUM_APP_MONITOR_ID: string | undefined;
  RUM_IDENTITY_POOL_ID: string | undefined;
  RISK_RADAR_URL: string | undefined;
}

const env: Readonly<Env> = {
  QUANT_API_URL: import.meta.env.VITE_QUANT_API_URL,
  AWS_REGION: import.meta.env.VITE_AWS_REGION,
  COGNITO_USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID,
  COGNITO_APP_CLIENT_ID: import.meta.env.VITE_COGNITO_APP_CLIENT_ID,
  APP_URL: import.meta.env.VITE_URL,
  CONTACT_EMAIL_ADDRESS: import.meta.env.VITE_CONTACT_EMAIL_ADDRESS,
  WITHDRAWAL_TOOL_URL: import.meta.env.VITE_WITHDRAWAL_TOOL_URL,
  STAKING_TOOL_URL: import.meta.env.VITE_STAKING_TOOL_URL,
  RUM_GUEST_ROLE_ARN: import.meta.env.VITE_RUM_GUEST_ROLE_ARN,
  RUM_APP_MONITOR_ID: import.meta.env.VITE_RUM_APP_MONITOR_ID,
  RUM_IDENTITY_POOL_ID: import.meta.env.VITE_RUM_IDENTITY_POOL_ID,
  RISK_RADAR_URL: import.meta.env.VITE_RISK_RADAR_URL,
};

export default env;
