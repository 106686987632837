import axios, { Axios, AxiosRequestConfig } from "axios";
import { USER_KEY, User, useAuth } from "./useAuth";
import env from "src/utils/env";
import { useEffect } from "react";

interface Fetcher extends Axios {
  get: <T>(url: string, config?: AxiosRequestConfig<any>) => Promise<T>;
  post: <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => Promise<T>;
  put: <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => Promise<T>;
  patch: <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => Promise<T>;
  delete: <T>(url: string, config?: AxiosRequestConfig<any>) => Promise<T>;
  options: <T>(url: string, config?: AxiosRequestConfig<any>) => Promise<T>;
}

const fetcher = axios.create({
  baseURL: env.QUANT_API_URL,
});

fetcher.interceptors.response.use(r => r.data);
fetcher.interceptors.request.use(async r => {
  const stored_user = localStorage.getItem(USER_KEY);

  if (stored_user) {
    const user = JSON.parse(stored_user) as User;
    fetcher.defaults.headers.common["Authorization"] = `Bearer ${user.tokens.idToken}`;
    r.headers["Authorization"] = `Bearer ${user.tokens.idToken}`;
  }

  return r;
});

export const endpoints = {
  tokens: {
    all: "/tokens",
    by_id: (token_id: string) => `/tokens/${token_id}`,
  },
  blockchains: {
    all: "/blockchains",
    by_id: (blockchain_id: string) => `/blockchains/${blockchain_id}`,
  },
  strategies: {
    all: "/strategies",
    by_id: (strategy_id: string) => `/strategies/${strategy_id}`,
    catalog: {
      integration_request: "/strategies/catalog/integration_request",
    },
  },
  portfolio_overview: {
    metrics: "/portfolio_overview/metrics",
  },
  positions: {
    all: "/positions",
    by_id: {
      metrics: (position_id: string) => `/positions/${position_id}/metrics`,
      management_events: (position_id: string) => `/positions/${position_id}/management_events`,
      returns_snapshots: (position_id: string) => `/positions/${position_id}/returns_snapshots`,
    },
  },
  staking: {
    validators: {
      kpis: "/staking/validators/kpis",
      eth: "/staking/validators/eth",
    },
  },
  metadata: {
    asset_categories: "/metadata/asset_categories",
  },
  user: {
    tenants: "/user/tenants",
  },
  discovery: {
    all: "/discovery",
    apy: `/discovery/apy`,
    integration_request: `/discovery/integration_request`,
  },
  risk_radar: {
    proxy: (path: string) => `/risk_radar${path}`
  }
};

export const useApi = () => {
  const { user, currentTenantId } = useAuth();

  useEffect(() => {
    if (user) {
      try {
        const stored_user = localStorage.getItem(USER_KEY)!;
        const parsed_user = JSON.parse(stored_user) as User;
        if (parsed_user?.tokens.idToken) fetcher.defaults.headers.common["Authorization"] = `Bearer ${parsed_user.tokens.idToken}`;

        fetcher.defaults.headers.common["x-tenant-id"] = currentTenantId ?? parsed_user?.tenantIds[0];
      } catch (err) {
        console.error(err);
      }
    }
  }, [user, currentTenantId]);

  return fetcher as Fetcher;
};
