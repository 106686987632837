import { useAuth } from "src/hooks/useAuth";
import { Avatar as UIAvatar } from "@itb/ui";

const get_initials = (str: string) => {
  if (!str) return "";
  if (str.length < 3) return str.toUpperCase();

  const take_initials = (parts: string[]) => `${parts[0][0]}${parts[1][0]}`.toUpperCase();

  const name = str.slice(0, str.indexOf("@"));
  let parts = name.split(".");
  if (parts.length > 1) return take_initials(parts);

  parts = name.split("-");
  if (parts.length > 1) return take_initials(parts);

  return `${name[0]}${name[1]}`.toUpperCase();
};

const Avatar = (props: { className?: string }) => {
  const { user } = useAuth();

  if (!user) return null;

  return <UIAvatar className={props.className}>{get_initials(user.username)}</UIAvatar>;
};

export default Avatar;
