import React from "react";
import { RouteConfig } from "./Router";

const PortfolioOverview = React.lazy(() => import("src/pages/PortfolioOverview"));
const Positions = React.lazy(() => import("src/pages/Positions"));
const Position = React.lazy(() => import("src/pages/Position"));
const Invoices = React.lazy(() => import("src/pages/Invoices"));
const Staking = React.lazy(() => import("src/pages/Staking"));

const privateRoutes: RouteConfig[] = [
  { path: "/", title: "Portfolio Overview", component: PortfolioOverview },
  { path: "/positions", title: "Positions", component: Positions },
  { path: "/positions/:positionId/:section?", component: Position },
  { path: "/invoices", title: "Invoices", component: Invoices },
  { path: "/staking", title: "ETH Staking", component: Staking },
];

export default privateRoutes;
