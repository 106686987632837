import { useRef, useState } from "react"
import { Anchor, Space, Tour, TourProps, Button, useWindowSize, BREAKPOINTS } from "@itb/ui"
import styled from "styled-components"
import env from "src/utils/env"
import { useAuth } from "src/hooks/useAuth"

const Content = styled.div`
  margin-top: 48px;
  margin-left: 20px;

  @media(max-width: ${BREAKPOINTS.mobile}) {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 20px;
    margin-bottom: 15px;
  }
`

const Title = styled.div`
  color: white;
  font-weight: 700;
  font-size: 16px;
`

const Description = styled.div`
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  @media(max-width: ${BREAKPOINTS.mobile}) {
    max-width: 300px;
  }
`

const Buttons = styled(Space)`
  margin-top: 20px;
`

const RISK_RADAR_TOUR_COOKIE = 'risk_radar_tour';

const RiskRadarItem = ({ children, mobile }: any) => {
  const { user } = useAuth();
  const riskRadarItemRef = useRef(null);
  const [open, setOpen] = useState(user ? localStorage.getItem(RISK_RADAR_TOUR_COOKIE) !== "false" : false);
  const { width } = useWindowSize();

  const dismiss = () => {
    localStorage.setItem(RISK_RADAR_TOUR_COOKIE, "false");
    setOpen(false);
  }

  const steps: TourProps['steps'] = [
    {
      title: "Introducing DeFi Risk Radar",
      target: () => riskRadarItemRef.current,
      placement: width > parseInt(BREAKPOINTS.mobile) ? "right" : "top",
      renderPanel: () => (
        <Content>
          <Title>Introducing DeFi Risk Radar</Title>
          <Description>A programmable and visual interface to access and monitor real time economic risk signals in DeFi protocols</Description>
          <Buttons>
            <Anchor href={`${env.RISK_RADAR_URL}?utm_source=quant`}>
              <Button onClick={dismiss}>Try Now</Button>
            </Anchor>
            <Button type="tertiary" onClick={dismiss} style={{ color: "white" }}>Skip</Button>
          </Buttons>
        </Content>
      ),
    },
  ];

  const isOpen = open && ((mobile && width <= parseInt(BREAKPOINTS.mobile)) || (!mobile && width > parseInt(BREAKPOINTS.mobile)));

  return (
    <>
      <Anchor href={`${env.RISK_RADAR_URL}?utm_source=quant`} ref={riskRadarItemRef} style={{ whiteSpace: "nowrap" }}>
        {children}
      </Anchor>
      <Tour 
        open={isOpen} 
        steps={steps} 
        onClose={() => setOpen(false)} 
        arrow={false} 
        closeIcon={false}
        mask={{
          color: "rgba(0, 0, 0, .8)",
        }}
      />
    </>
  )
}

export default RiskRadarItem;