import { BREAKPOINTS, Button, useErrorBoundary, useIsFirstRender } from "@itb/ui";
import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import ErrorImage from "src/assets/error-dog.svg";
import styled from "styled-components";

const Container = styled.div`
  height: calc(100vh - ${props => props.theme.navbarHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  
  @media (max-width: ${BREAKPOINTS.mobile}) {
    height: calc(100vh - ${props => props.theme.navbarHeight} - ${props => props.theme.sidebarMobileHeight});
  }
`

const Image = styled.img`
  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 300px;
  }
`

const Text = styled.div`
  margin-top: 50px;
  font-size: 32px;
  font-weight: 700;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 22px;
  }
`

const Subtext = styled.div`
  margin: 16px 0px;
  font-size: 14px;
  line-height: 20px;
`

const Error = () => {
  const isMountedRef = useRef(true);
  const location = useLocation();
  const { resetBoundary } = useErrorBoundary();
  const isFirstRender = useIsFirstRender();
  
  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    if (!isFirstRender)
      resetBoundary();
  }, [location, resetBoundary, isFirstRender]);

  return (
    <Container>
      <Image src={ErrorImage} alt="error robot" />
      <Text>Whoops! An error ocurred</Text>
      <Subtext>We seem to be having some internal problems.<br/>Please try again later</Subtext>
      <Link to="/"><Button type="primary" size="large">Go Back to Homepage</Button></Link>
    </Container>
  )
}

export default Error;