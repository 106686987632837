import { Loader, useUI } from "@itb/ui";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { endpoints, useApi } from "./useApi";
import { AssetCategories, Blockchain, Strategy, Token } from "src/types/api";
import { useAuth } from "./useAuth";
import { DiscoveryItem } from "src/types/discovery";
import { Position } from "src/types/positions";

interface AppData {
  tokens: Token[];
  blockchains: Blockchain[];
  strategies: Strategy[];
  assetCategories: AssetCategories;
  discovery?: DiscoveryItem[];
  fetchDiscovery?: () => Promise<void>;
  discoveryLoading: boolean;
  positions?: Position[];
  fetchPositions?: () => Promise<void>;
  positionsLoading: boolean;
}

export interface App extends AppData {}

const AppContext = createContext<App>({} as App);

export const useApp = () => useContext(AppContext);

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100svh;
`;

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [data, setData] = useState<AppData>({} as AppData);
  const [loading, setLoading] = useState(true);
  const fetcher = useApi();
  const { user, tenant } = useAuth();
  const [positionsLoading, setPositionsLoading] = useState(false);
  const [discoveryLoading, setDiscoveryLoading] = useState(false);
  const { setFormatterShowExactNumbers } = useUI();

  const fetchData = useCallback(async () => {
    setLoading(true);

    if (user) {
      const [tokens, assetCategories] = await Promise.all([
        fetcher.get<Token[]>(endpoints.tokens.all), 
        fetcher.get<AssetCategories>(endpoints.metadata.asset_categories),
      ]);

      setData(prevData => ({
        ...prevData,
        tokens,
        assetCategories,
      }));
    }

    setLoading(false);
  }, [fetcher, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchPublicData = useCallback(async () => {
    setLoading(true);

    const [blockchains, strategies] = await Promise.all([
      fetcher.get<Blockchain[]>(endpoints.blockchains.all), 
      fetcher.get<Strategy[]>(endpoints.strategies.all), 
    ]);

    setData(prevData => ({
      ...prevData,
      blockchains,
      strategies,
    }));

    setLoading(false);
  }, [fetcher]);

  useEffect(() => {
    fetchPublicData();
  }, [fetchPublicData]);

  const fetchPositions = useCallback(async () => {
    setPositionsLoading(true);
    setData(prevData => ({ ...prevData, positions: [] }));
    const positions = await fetcher.get<Position[]>(endpoints.positions.all, { params: { fetchBalances: true } });
    setData(prevData => ({ ...prevData, positions }));
    setPositionsLoading(false);
  }, [fetcher]);

  const fetchDiscovery = useCallback(async () => {
    setDiscoveryLoading(true);
    setData(prevData => ({ ...prevData, discovery: [] }));
    const discovery = await fetcher.get<DiscoveryItem[]>(endpoints.discovery.all);
    setData(prevData => ({ ...prevData, discovery }));
    setDiscoveryLoading(false);
  }, [fetcher]);

  // Tenant dependant data
  useEffect(() => {
    if (!tenant)
      return;

    fetchPositions();
    setFormatterShowExactNumbers(tenant.config?.ui?.show_exact_numbers ?? false);
  }, [fetchPositions, setFormatterShowExactNumbers, tenant])

  useEffect(() => {
    fetchDiscovery();
  }, [fetchDiscovery]);

  if (loading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  const value = { 
    ...data,
    fetchPositions,
    positionsLoading,
    fetchDiscovery,
    discoveryLoading,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
