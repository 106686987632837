import { Button, Dropdown, Icon, Space, ThemeSwitch, Navbar as UINavbar, BREAKPOINTS, DropdownItem } from "@itb/ui";
import { useAuth } from "src/hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Avatar from "../Avatar";
import Search from "../Search";
import { useState } from "react";

const StyledNavbar = styled(UINavbar)``;

const Tools = styled.div`
  display: flex;
  align-items: center;
`;

const UserAvatar = styled(Avatar)`
  min-width: 32px;
`;

const UserName = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
`;
const TenantName = styled.div`
  font-size: 13px;
  margin-left: 4px;
  color: ${props => props.theme.colors.darkgray};
  white-space: nowrap;
`;

const UserDropdown = styled(Dropdown)`
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  ${UserAvatar} {
    padding-right: 15px;
  }
`;

const UserDropdownContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Desktop = styled(Space)`
  display: flex;
  padding-left: 10px;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: none;
  }
`;

const Mobile = styled(Space)`
  display: none;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    display: flex;
    align-items: center;
  }
`;

const ThemeSwitchItem = styled(ThemeSwitch)`
  width: 100%;
  background: none;
  border: none;
`;

const SearchButton = styled(Icon)`
  cursor: pointer;
  display: none;

  @media(max-width: ${BREAKPOINTS.tablet}) {
    display: block;
  }
  @media(max-width: ${BREAKPOINTS.mobile}) {
    display: block;
    margin-right: 10px;
  }
`;

const MenuIcon = styled(Icon)`
  margin-top: 4px;
`

const Navbar = () => {
  const { user, tenant, tenants, setCurrentTenantId, signOut } = useAuth();
  const [searchOpen, setSearchOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const signIn = () => navigate("/signin", { state: { redirectUri: `${location.pathname}${location.search}` } });

  const signedInItems: DropdownItem[] = [
    ...(tenants && tenants.length > 1 ? [
      {
        key: "tenant",
        label: "Tenants",
        icon: "group",
        popupStyle: { overflow: "auto", maxHeight: "302px" },
        
        children: tenants?.map(tenant => ({
          key: tenant.tenant_id,
          label: tenant.name,
          onClick: () => setCurrentTenantId(tenant.tenant_id),
        })).sort((a, b) => a.label.localeCompare(b.label)),
      } as DropdownItem,
      { key: "divider", type: "divider" } as DropdownItem,
    ] : []),
    { key: "signout", label: "Sign out", icon: "logout", onClick: signOut },
  ];

  const anonymousItems: DropdownItem[] = [{ key: "signin", label: "Sign In", icon: "login", onClick: signIn }];

  const tools = (
    <Tools>
      <Search
        open={searchOpen}
        setOpen={setSearchOpen}
      />
      <SearchButton icon={searchOpen ? "close" : "search"} onClick={() => setSearchOpen(p => !p)}/>
      <Desktop size={20}>
        <ThemeSwitch />
        {user ? (
          <UserDropdown items={signedInItems}>
            <UserDropdownContent>
              <UserAvatar />
              <UserName>{user.username}</UserName>
              <TenantName>({tenant?.name})</TenantName>
              <Icon icon="expand_more" />
            </UserDropdownContent>
          </UserDropdown>
        ) : (
          <Button onClick={signIn}>Sign In</Button>
        )}
      </Desktop>
      <Mobile>
        <Dropdown 
          items={[
            { key: "theme-switch", label: <ThemeSwitchItem /> }, 
            ...(user ? signedInItems : anonymousItems)
          ]}
        >
          <MenuIcon icon="menu" />
        </Dropdown>
      </Mobile>
    </Tools>
  );

  return <StyledNavbar tools={tools} />;
};

export default Navbar;
