import { AwsRum, AwsRumConfig } from "aws-rum-web";
import env from "src/utils/env";

let RUM: AwsRum | null = null;

try {
  if (env.RUM_GUEST_ROLE_ARN && env.RUM_IDENTITY_POOL_ID && env.AWS_REGION && env.RUM_APP_MONITOR_ID) {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: env.RUM_GUEST_ROLE_ARN,
      identityPoolId: env.RUM_IDENTITY_POOL_ID,
      endpoint: `https://dataplane.rum.${env.AWS_REGION}.amazonaws.com`,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: false,
      sessionAttributes: {
        appId: 'ITBQuantPortalFrontend'
      }
    }

    RUM = new AwsRum(env.RUM_APP_MONITOR_ID, '1.0.0', env.AWS_REGION, config);
  }
} catch (error) {
  console.log("An error occurred while initializing RUM:", error)
}

export default RUM;