import ReactDOM from "react-dom/client";
import { UIProvider } from "@itb/ui";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "src/hooks/useAuth";
import { AppProvider } from "src/hooks/useApp";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <UIProvider>
      <AuthProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </AuthProvider>
    </UIProvider>
  </BrowserRouter>
);