import { format } from "@itb/ui";

export const sleep = async (ms: number) => await new Promise(r => setTimeout(r, ms));

export const safeOpenUrl = (url: string) => window.open(url, "noopener noreferrer");

const STRATEGY_ID_FORMAT_OVERRIDES: { [key: string]: string } = {
  eigenlayer_staking: "EigenLayer Staking"
}

export const format_strategy_id = (strategy_id: string) => STRATEGY_ID_FORMAT_OVERRIDES[strategy_id] ?? strategy_id?.split("_").map(format.capitalize).join(" ");
