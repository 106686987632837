import { Amplify } from "aws-amplify";
import { Loader } from "@itb/ui";
import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Tenant } from "src/types/api";
import axios from "axios";
import { signIn as amplifySignIn, signOut as amplifySignOut, updatePassword as amplifyUpdatePassword, fetchAuthSession, confirmSignIn as amplifyConfirmSignIn, resetPassword as amplifyResetPassword, confirmResetPassword as amplifyConfirmResetPassword } from "aws-amplify/auth";
import { Hub } from 'aws-amplify/utils';
import env from "src/utils/env";
import { useLocation, useNavigate } from "react-router-dom";

export interface Tokens {
  idToken: string;
  accessToken: string;
}

export interface User {
  username: string;
  tokens: Tokens;
  tenantIds: string[];
}

export interface Auth {
  user?: User;
  currentTenantId?: string;
  setCurrentTenantId: (tenant_id: string) => void;
  tenant?: Tenant;
  tenants?: Tenant[];

  signIn: (username: string, password: string) => Promise<void>,
  signOut: () => Promise<void>,
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>,
  confirmSignIn: (newPassword: string) => Promise<any>,
  resetPassword: (username: string) => Promise<any>,
  confirmResetPassword: (username: string, confirmationCode: string, newPassword: string) => Promise<void>,
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: env.COGNITO_USER_POOL_ID ?? '',
      userPoolClientId: env.COGNITO_APP_CLIENT_ID ?? '',
    }
  }
});

const AuthContext = createContext<Auth>({} as Auth);

export const useAuth = () => useContext(AuthContext);

export const USER_KEY = "itb__user";
export const CURRENT_TENANT_KEY = "itb__current_tenant";

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100svh;
`;

export const validatePassword = (password: string, repeatPassword: string): [boolean, string][] => [
  [/(?=.*[a-z])/.test(password), "Password must contain a lower case letter"],
  [/(?=.*[A-Z])/.test(password), "Password must contain an upper case letter"],
  [/(?=.*\d)/.test(password), "Password must contain a number"],
  [password.length >= 8, "Password must contain at least 8 characters"],
  [password === repeatPassword, "Passwords must match"],
  [/(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/.test(password), "Password must contain a special character or a space"],
  [/^(?! ).*(?<! )$/.test(password), "Password must not contain a leading or trailing space"],
];

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [tenants, setTenants] = useState<Tenant[]>();
  const [currentTenantId, setCurrentTenantId] = useState(localStorage.getItem(CURRENT_TENANT_KEY) ?? undefined);
  const navigate = useNavigate();
  const location = useLocation();

  const signIn = async (username: string, password: string) => {
    const result = await amplifySignIn({ username, password });
    if (result.isSignedIn)
      return;

    if (result.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED')
      navigate('/change-password', { state: { ...location.state, from: "signin" } });
  }

  const signOut = () => amplifySignOut();

  const updatePassword = (oldPassword: string, newPassword: string) => amplifyUpdatePassword({ oldPassword, newPassword });
  const confirmSignIn = (newPassword: string) => amplifyConfirmSignIn({ challengeResponse: newPassword });
  const resetPassword = (username: string) => amplifyResetPassword({ username });
  const confirmResetPassword = (username: string, confirmationCode: string, newPassword: string) => amplifyConfirmResetPassword({ username, confirmationCode, newPassword });

  const updateUser = async () => {
    const authSession = await fetchAuthSession();

    if (authSession.tokens) {
      const user: User = {
        tenantIds: authSession.tokens.accessToken.payload['cognito:groups'] as string[],
        tokens: {
          accessToken: authSession.tokens.accessToken.toString(),
          idToken: authSession.tokens.idToken!.toString(),
        },
        username: authSession.tokens.accessToken.payload.username as string,
      }

      const currentTenantId = localStorage.getItem(CURRENT_TENANT_KEY) ?? user.tenantIds[0];

      setUser(user);
      setCurrentTenantId(currentTenantId);
      
      localStorage.setItem(USER_KEY, JSON.stringify(user));
      localStorage.setItem(CURRENT_TENANT_KEY, currentTenantId);
      return user;
    } else {
      setUser(undefined);
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(CURRENT_TENANT_KEY);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        await updateUser();
        setLoading(false);
      } catch (err) {
        console.log("AUTH ERR:", err)
      }
    }

    const stopListening = Hub.listen('auth', async data => {
      switch (data.payload.event) {
        case 'signedIn': {
          await updateUser();
          break;
        }
        case 'signedOut': {
          await updateUser();
          break;
        }
        case 'tokenRefresh_failure': {
          await updateUser();
          break;
        }
      }
    });

    init();

    return () => { stopListening() };
  }, []);

  useEffect(() => {
    const fetchTenants = async () => {
      if (user) {
        const headers = {
          Authorization: `Bearer ${user.tokens.idToken}`,
          "x-tenant-id": "unknown",
        };

        const tenants = await axios.get(`${env.QUANT_API_URL}/user/tenants`, { headers }).then(r => r.data as Tenant[]);
        setTenants(tenants);
      } else {
        setTenants([]);
      }
    };

    fetchTenants();
  }, [user]);

  const updateCurrentTenantId = (tenant_id: string) => {
    if (!tenants) return;
    if (!tenants.find(t => t.tenant_id === tenant_id)) tenant_id = tenants[0].tenant_id;

    setCurrentTenantId(tenant_id);
    localStorage.setItem(CURRENT_TENANT_KEY, tenant_id);
  };

  const value: Auth = {
    user,
    currentTenantId,
    setCurrentTenantId: updateCurrentTenantId,
    tenants,
    tenant: tenants?.find(t => t.tenant_id === currentTenantId) ?? tenants?.[0],
    signIn,
    signOut,
    updatePassword,
    confirmSignIn,
    resetPassword,
    confirmResetPassword,
  };

  if (loading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
