import { Suspense, useEffect } from "react";
import { Route, RouteProps, Routes, useLocation, useNavigate } from "react-router-dom";
import { ErrorBoundary, useUI } from "@itb/ui";
import privateRoutes from "./private";
import publicRoutes from "./public";
import { useAuth } from "src/hooks/useAuth";
import Error from "src/components/Error";

export type RouteConfig = RouteProps & {
  title?: string;
  component: any;
};

const PrivateRoute = ({ children }: { children: React.ReactElement<RouteProps> }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user)
      navigate("/signin", { state: { redirectUri: `${location.pathname}${location.search}` } });
  }, [user, navigate, location])

  return user ? <>{children}</> : null;
};

const RouteWrapper = (route: RouteConfig) => {
  const Component = route.component;
  const { setTitle } = useUI();

  useEffect(() => {
    if (route.title) setTitle(route.title);
  }, [setTitle, route.title]);

  return <Component />;
};

const Router = () => {
  return (
    <ErrorBoundary fallback={<Error />}>
      <Suspense>
        <Routes>
          {publicRoutes.map(route => (
            <Route key={route.path} {...route} element={<RouteWrapper {...route} />} />
          ))}
          {privateRoutes.map(route => (
            <Route
              key={route.path}
              {...route}
              element={
                <PrivateRoute>
                  <RouteWrapper {...route} />
                </PrivateRoute>
              }
            />
          ))}
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
